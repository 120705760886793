import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "lh-20 mb-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_stylized_text = _resolveComponent("tm-stylized-text")!
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_tm_form_line = _resolveComponent("tm-form-line")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: "singleAssignee",
    size: "small"
  }, {
    "modal-content": _withCtx(() => [
      (_ctx.text)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_tm_stylized_text, {
              class: "body-text-regular-14 lh-20 neutral--text",
              text: _ctx.text
            }, null, 8, ["text"])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_tm_form_line, { label: _ctx.label }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_field, {
            modelValue: _ctx.assignTo,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.assignTo) = $event)),
            options: _ctx.assigneeOptions,
            type: "assignee",
            placeholder: _ctx.placeholder,
            autofocus: "",
            "empty-placeholder": "Select user"
          }, null, 8, ["modelValue", "options", "placeholder"])
        ]),
        _: 1
      }, 8, ["label"])
    ]),
    "modal-footer-button": _withCtx(() => [
      _createVNode(_component_tm_button, {
        color: "primary",
        size: "large",
        disable: !_ctx.assignTo
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.btnText), 1)
        ]),
        _: 1
      }, 8, ["disable"])
    ]),
    _: 1
  }))
}